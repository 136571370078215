import React, {useEffect, useState} from 'react';
import {useTranslation} from "../lang/TranslationContext";
import axios from "axios";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  

const Modals = ({tg, handleOverlayClick, activeModal, currentBalance, openModal, currentWallet, fetchHistory}) => {
    const  payWallet = "UQD-zvEaIid8enzfRtzQ91fytZHpytqT6HiXSruZI2maOWN9"
    const {t} = useTranslation();
    const wallet = useTonWallet();
    const [tonConnectUi, connected ] = useTonConnectUI();
    const [amount, setAmount] = useState(10);
    const [out, setOut] = useState(1);
    const navigate = useNavigate();

    const updateAmount = (newAmount) => {
        if(newAmount > 10) {
            newAmount = 10
        } else if(newAmount < 0) {
            newAmount = 0.01
        }
        setAmount(newAmount)
    }

    const setWallet = async (walletAddress) => {
            try {
            const initData = tg && tg.initData ? tg.initData : null;
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/setWallet.php`, {
                            method: 'POST',
                            headers: {
                                    'Authorization': initData || '',
                                    'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({'wallet': walletAddress}),
                    });

                    const result = await response.json();
                    if (result.status === 'success') {
                   
                    } else {
                        alert(result.message)
                    }
            } catch (error) {
                console.log(error)
            }
    };

    useEffect(()=>{
        if (wallet) {
            if(currentWallet !== false) {
                const walletAddress = wallet.account.address; 
                if(walletAddress !== currentWallet) {
                    setWallet(walletAddress)
                }
            }
           
        }
    }, [wallet, currentWallet])

    const sendTransaction = async (amountInTon) => {
        if (!connected || !wallet) {
          console.error('Wallet is not connected');
          return;
        }
    
        try {
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 3600, 
            messages: [
              {
                address: payWallet,
                amount: (amountInTon * 1e9).toString(), 
                payload: '',
              },
            ],
          };
    
          const result = await tonConnectUi.sendTransaction(transaction);
          navigate("/");
        } catch (error) {
          console.log('Failed to send transaction:', error);
        }
      };


    const withdrawMoney = async () => {
        try {
          const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/createWithdraw.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                          'currency_id': 3, 
                          'amount' : out
                        }),
                });

                const result = await response.json();
                if (result.status === 'success') {
                  alert(result.message)
                  fetchHistory()
                } else {
                     alert(result.message)
                }
        } catch (error) {
                console.log(error)
        }
};


    return (
        <>
            <div className={`overlay fixed-block ${activeModal ? 'active' : ''}`} data-overlay
                 onClick={handleOverlayClick}>


                <div className={`modal ${activeModal === 'wallet' ? 'active' : ''}`} data-popup="wallet"
                     style={activeModal === 'wallet' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <button className="modal__close close">
                        <svg width="24" height="24">
                            <use href="img/sprite/sprite.svg#close"></use>
                        </svg>
                    </button>
                    <div className="modal__top">
                        <span className="modal__title">
                            {t("Withdraw")}</span>
                        <p>{t('This amount will be sent to your TON wallet.')}</p>
                    </div>

                    <div className="modal__box">
                        <form className="main-form main-form--mode">

                            <div className="main-form__inner">
                                <label htmlFor="coin_2" className="main-form__label">
                                <span className="main-form__icon">
                                    <svg width="33" height="33">
                                        <use href="img/sprite/sprite.svg#ton"></use>
                                    </svg>
                                </span>
                                    <input type="number" min="100" name="coin_2" id="coin_2" value={out}
                                           onChange={(event) => setOut(event.target.value)}
                                           className="main-form__input" placeholder="Enter amount"/>
                                </label>
                                <div className="main-form__wrapp">
                                    <span className="main-form__text">{t('Min amount')}:</span>
                                    <span className="main-form__value">1 TON


                            <svg width="20" height="20">
                                <use href="img/sprite/sprite.svg#ton"></use>
                            </svg></span>


                                </div>
                                <p>
                                   {t('At the moment you have available for withdrawal')} {currentBalance} {t('TON. Withdrawals are processed within 48 hours, but in some cases may take up to 5 business days. Thank you for your understanding and patience.')}
                                </p>
                            </div>

                            {wallet ? (<>
                                <button onClick={() => {
                                        withdrawMoney()
                                    }} className="main-btn" data-btn-inner="succes">
                                        <span className="main-btn__bg"></span>
                                        {t("Withdraw")}
                                    </button>
                                </>
                            ) : (<>
                                <p>{t('Connect your TON wallet for payment')}</p>
                                <button type="button" onClick={() => tonConnectUi.openModal()} className="main-btn"
                                        data-btn-inner="succes"
                                        data-btn-inner="wallet">
                                    <span className="main-btn__bg"></span>
                                    {t('Connect Wallet')}
                                </button>
                            </>)}
                        </form>
                    </div>
                </div>

                <div className={`modal ${activeModal === 'connect' ? 'active' : ''}`} data-popup="connect"
                     style={activeModal === 'connect' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <button className="modal__close close">
                        <svg width="24" height="24">
                            <use href="img/sprite/sprite.svg#close"></use>
                        </svg>
                    </button>
                    <div className="modal__top">
                        <span className="modal__title">
                            {t('Order details')}
                        </span>
                    </div>
                    <div className="modal__box">
                        <div className="modal__top modal__top--mode">
                            <ul className="modal__list">
                                <li className="modal__item">
                                    <span
                                        className="modal__text">{t('Power')}:</span>
                                                <span className="modal__value"><svg width="24" height="24">
                                      <use href="img/sprite/sprite.svg#eco"></use>
                                    </svg>
                                        <b>{Number(amount * 10000).toFixed(2)} Gh/s </b></span>
                                </li>
                                <li className="modal__item">
                                    <span className="modal__text">{t('Rental period')}:</span>
                                    <span className="modal__value"><b>30 {t('days')}</b></span>
                                </li>
                                <li className="modal__item">
                                <span
                                    className="modal__text">{t('Total income')}:</span>
                                    <span className="modal__value"> <svg width="20" height="20">
                          <use href="img/sprite/sprite.svg#ton"></use>
                        </svg><b>{Number(amount * 1.2).toFixed(2)} - {Number(amount * 2.4).toFixed(2)} TON</b></span>
                                </li>
                                <li className="modal__item">
                                <span
                                    className="modal__text">{t('Daily income')}:</span>
                                    <span className="modal__value"><svg width="20" height="20">
                          <use href="img/sprite/sprite.svg#ton"></use>
                        </svg>
                                        <b>{Number(amount * 1.2 / 30).toFixed(2)} - {Number(amount * 2.4 / 30).toFixed(2)} TON</b></span>
                                </li>
                                <li className="modal__item">
                                <span className="modal__text">{t('Price')}:</span>
                                    <span className="modal__value">
                                        <svg width="20" height="20">
                          <use href="img/sprite/sprite.svg#ton"></use>
                        </svg>
                                        <b> {amount} TON</b></span>
                                </li>
                            </ul>

                            <span className="modal__title">{t('Payment')}</span>

                                <div className="modal__inner">
                                    <p>{t("After you've made the payment, the deposit will be credited to your balance within 5 minutes. Once the payment is complete, you can close this window.")}</p>
                                    {wallet ? (<>
                                            <button type="button" onClick={() => {
                                                sendTransaction(amount)
                                            }}  className="main-btn main-btn--blue"  data-btn-inner="wallet">
                                                <span className="main-btn__bg"></span>
                                                <span className="main-btn__icon">
                                                <svg width="21" height="21">
                                                    <use href="img/sprite/sprite.svg#icon"></use>
                                                </svg>
                                                </span>
                                                {t('Pay')}
                                            </button>
                                            <span className="modal__pretitle">{t('or')}</span>

                                            <p>{t('Send to the specified deposit address from your wallet')}.</p>
                                        </>
                                    ) : (<>

                                        <p>{t('Connect your TON wallet to pay for your order')}</p>
                                        <button onClick={() => tonConnectUi.openModal()}
                                                className="main-btn main-btn--blue" data-btn-inner="succes">
                                            <span className="main-btn__bg"></span>
                                            <span className="main-btn__icon">
                                    <svg width="21" height="21">
                                        <use href="img/sprite/sprite.svg#icon"></use>
                                    </svg>
                                    </span>
                                            {t('Connect Wallet')}
                                        </button>
                                    </>)}

                                    <button className="transparent-btn close">{t('Cancel')}</button>
                                </div>


                        </div>
                    </div>


                    {wallet ? (<form className="main-form withdraw">
                        <div className="main-form__box">
                            <div className="main-form__qr">
                                <img width='182' height='184' src='img/qr.png' alt='image'/>
                            </div>

                            <span className="main-form__value"><b>{amount}</b> TON</span>

                            <label className="main-form__label" htmlFor="address_1" data-parent-copy>
                                <input onClick={() => {
                                    copyText(payWallet)
                                }} readOnly type="text" name="address_1" id="address_1" className="main-form__input"
                                       value={payWallet}/>

                                <button type="button" onClick={() => {
                                    copyText(payWallet)
                                }} className="copy-btn" data-copy>
                                    <img width='24' height='24' src='img/sprite/Copy.svg' alt='image'/>
                                </button>
                            </label>
                        </div>
                    </form>) : <></>}

                </div>

                <div className={`modal ${activeModal === 'buy' ? 'active' : ''}`} data-popup="buy"
                     style={activeModal === 'buy' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <button className="modal__close close">
                        <svg width="24" height="24">
                            <use href="img/sprite/sprite.svg#close"></use>
                        </svg>
                    </button>
                    <div className="modal__top">
                        <span className="modal__title">
                            <svg width="24" height="24">
                                <use href="img/sprite/sprite.svg#flash2"></use>
                            </svg>
                            {t("Buy boost")}
                        </span>
                        <p>{t('Buy mining power for 30 days and earn more TON. The more power, the higher the income on the Tonium platform.')}</p>
                    </div>


                    <div className="modal__box">
                        <div className="main-form__inner">
                            <label htmlFor="coin" className="main-form__label">
                        <span className="main-form__icon">
                            <svg width="33" height="33">
                                <use href="img/sprite/sprite.svg#ton"></use>
                            </svg>
                        </span>
                                <input type="number" min="0.01" max="10" name="coin" id="coin" className="main-form__input"
                                       placeholder="Enter amount" value={amount}
                                       onChange={(event) => updateAmount(event.target.value)}/>
                            </label>
                            <div className="main-form__wrapp">
                                <span className="main-form__text">{t('Min amount')}:</span>
                                <span className="main-form__value">1 TON
                            <svg width="20" height="20">
                                <use href="img/sprite/sprite.svg#ton"></use>
                            </svg></span>
                            </div>
                            <div className="main-form__wrapp">
                                <span className="main-form__text">{t('Max amount')}:</span>
                                <span className="main-form__value">10 TON <svg width="20" height="20">
                                <use href="img/sprite/sprite.svg#ton"></use>
                            </svg></span>
                            </div>
                        </div>

                        <button onClick={() => {
                            openModal('connect')
                        }} className="main-btn main-btn--blue" data-btn-inner="order">
                            <span className="main-btn__bg"></span>
                            {t("Buy boost")}
                        </button>
                    </div>


                </div>


            </div>
        </>
    );
};

export default Modals;
