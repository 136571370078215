import React, {useEffect, useState, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import axios from 'axios';
import Mining from '../components/Mining';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";



const Home = ({tg, openModal, setCurrentBalance, setCurrentWallet}) => {

        

        const [proc, setProc] = useState(0.03);
const calculateSpeed = (power, percentMine = 100) => {
  let budget = 0.0001 * power;
  let proc_sec = proc / (86400);
  let zarabotok = budget * proc_sec;

  return zarabotok
     
}

    const { t } = useTranslation();
    const [data, setData] = useState([]);

    const [activeModal, setActiveModal] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
  
  const [secondPlus, setSecondPlus] = useState(0)

  const [speed, setSpeed] = useState(0)

  useEffect(()=> {
    if(data && data.power) {
      setSpeed(calculateSpeed(Number(data.power), Number(data.mining["mining_3"])))
    }
      
  }, [data]);

  const [nowMoney, setNowMoney] = useState(0)
    useEffect(() => {
      console.log('speed', speed)
        const incrementMoney = () => {
            setNowMoney(prevMoney => Number(prevMoney) + Number(speed) / 10 );
        };

        const intervalId = setInterval(incrementMoney, 100);

        return () => clearInterval(intervalId);
    }, [speed]); 

    const fetchData = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`, 
        {
          headers: {
            'Authorization': initData
        },
      }
      )
      .then(response => {
        setData(response.data);
        setNowMoney(response.data.user['balance_3'])
        setCurrentBalance(response.data.user['balance_3'])
        setCurrentWallet(response.data.user['wallet'])
        setProc(response.data.proc)

      }).catch(error => {
        console.log('Error fetching data:', error);
      });

    };

    useEffect(() => {
      if(tg) {
        fetchData();
      }
    }, [tg]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, []); 

    return (
        <>
        
            <main >
                <section className="miners-section">
                    <div className="container">
                        <div className="miners-section__box">

                            <div className="main-total">
                                <div className="main-total__inner">
                                    <div className="main-total__icon">
                                        <svg width="35" height="35">
                                            <use href="img/sprite/sprite.svg#ton"></use>
                                        </svg>
                                    </div>
                                    <span className="main-total__value">
                                    <b>{Number(nowMoney).toFixed(9)}</b> TON
                                  </span>
                                </div>
                                <span className="main-total__currency">
                                  ⚡{data && data.power ? Number(data.power).toFixed(2) : "0.00" } gh/s
                                </span>
                                <div className="main-total__btns">
                                    <button type="button" onClick={() => {
                                        openModal('wallet')
                                    }} className="main-btn" data-btn-modal="withdraw">{t('Withdraw')}</button>
                                    <button type="button" onClick={() => {
                                        openModal('buy')
                                    }} className="main-btn main-btn--blue" data-btn-modal="buy">{t('Buy boost')} 🚀
                                    </button>
                                </div>
                            </div>

                            <div className="miners-section__video">
                                <video width='390' height='392' playsInline muted autoPlay loop>
                                    <source src="./img/CM1_end.webm" type="video/webm"/>
                                    <source src="./img/CM1_end.mp4" type="video/mp4"/>
                                </video>
                            </div>


                        </div>
                    </div>


                </section>
            </main>
        </>
    );
};

export default Home;
