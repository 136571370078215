import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from "../lang/TranslationContext";

const Footer = (page, setActivePage) => {
    const { t } = useTranslation();
    return (
        <ul className="bar">
                <li className="bar__item">
                    <NavLink to={"/withdraw"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                        <span className="bar__icon">
                          <img width="24" height="24" src="./img/sprite/icon1.svg" alt="image"/>
                        </span>
                        {t('Wallet')}
                    </NavLink>
                </li>

                <li class="bar__item">
                    <NavLink to={"/friends"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                        <span class="bar__icon">
                          <img width="24" height="24" src="./img/sprite/icon2.svg" alt="image"/>
                        </span>
                        {t('Friends')}
                    </NavLink>
                </li>

                <li className="bar__item">
                    <NavLink to={"/"}
                             className={({isActive}) => isActive ? "bar__link mode active" : "bar__link mode"}>
                        <img width="24" height="24" src="./img/sprite/icon3.svg" alt="image"/>
                    </NavLink>
                </li>

            <li className="bar__item">
                <NavLink to={"/tasks"} className={({isActive}) => isActive ? "bar__link active" : "bar__link"}>
                    <span className="bar__icon">
                       <img width="24" height="24" src="./img/sprite/icon4.svg" alt="image"/>
                    </span>
                {t('Tasks')}
                </NavLink>
            </li>
            <li class="bar__item">
                <a href="https://t.me/tonium_news" target="_blank" class="bar__link">
                    <span class="bar__icon">
                        <img width='24' height='24' src="./img/sprite/icon5.svg" alt="image"/>
                    </span>
                    {t('Support')}
                </a>
            </li>
        </ul>
);
};

export default Footer;
